import { buildEntityCallbacks, buildSchema, EntityOnDeleteProps, EntityOnSaveProps, buildCollection } from "@camberi/firecms";

export type Tag = {
  status: string;
  title: string;
  search: string[];
  date: Date;
  modified: Date;
  nameStr: string;
  wp_id: string;
  slug: string;
};

export const tagsSchema = buildSchema<Tag>({
  name: "Tag",
  description: "Tags across the app",

  defaultValues: {
    date: new Date(),
    status: "active",
  },
  properties: {
    date: {
      dataType: "timestamp",
      disabled: {
        hidden: true
      },
      validation: {
        required: true
      }
    },
    modified: {
      dataType: "timestamp",
      disabled: {
        hidden: true
      }
    },
    search: {
      dataType: "array",
      disabled: {
        hidden: true
      }
    },
    title: {
      title: "Título",
      validation: { required: true },
      dataType: "string",
    },
    nameStr: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    },
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      config: {
        enumValues: {
          active: "Activo",
          inactive: "Inactivo"
        },
      },
    },
    wp_id: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    },
    slug: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    }
  },
});

export const tagCallbacks = buildEntityCallbacks({
  onPreSave: ({
    schema,
    path,
    entityId,
    values,
    status
  }) => {
    values.modified = new Date();
    values.nameStr = '';
    values.search = [];
    values.slug = '';
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Tag>) => {
    //console.log("onSaveSuccess", props);
  },
  onSaveFailure: (props: EntityOnSaveProps<Tag>) => {
    // console.log("onSaveFailure", props);
  },
  onPreDelete: ({
    schema,
    path,
    entityId,
    entity,
    context
  }: EntityOnDeleteProps<Tag>
  ) => {
    // if (context.authController.user)
    //   throw Error("Product deletion not allowed");
  },

  onDelete: (props: EntityOnDeleteProps<Tag>) => {
    //console.log("onDelete", props);
  },
});

export const buildTag = buildCollection({
  path: "tags",
  schema: tagsSchema,
  name: "Tags",
  initialSort: ["nameStr", "asc"],
  group: "Otros",
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("administrador"),
    create: authController.extra.roles.includes("administrador"),
    delete: authController.extra.roles.includes("administrador"),
  }),
  callbacks: tagCallbacks,
  defaultSize: "xs",
  inlineEditing: true,
  textSearchEnabled: false,
  properties: ["title"],
  subcollections: [],
  exportable: false,
  selectionEnabled: true,
  pagination: true,
  filterCombinations: [{ nameStr: "desc" }]
});