import { buildEntityCallbacks, buildSchema, EntityOnSaveProps, buildCollection } from "@camberi/firecms";

export type Specialty = {
    name: string;
    description: string;
    date: Date;
    modified: Date;
    nameStr: string;
    wp_id: string;
    slug: string;
    status: string;
    search: string[];
};

export const specialtySchema = buildSchema<Specialty>({
    name: "Especialidad",
    defaultValues: {
        date: new Date(),
        status: "active"
    },
    properties: {
        date: {
            dataType: "timestamp",
            disabled: {
                hidden: true
            },
            validation: {
                required: true
            }
        },
        modified: {
            dataType: "timestamp",
            disabled: {
                hidden: true
            }
        },
        nameStr: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        name: {
            title: "Nombre",
            validation: { required: true },
            dataType: "string",
        },
        description: {
            title: "Descripción",
            validation: { required: false },
            dataType: "string",
            config: {
                multiline: true,
                markdown: true
            }
        },
        wp_id: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        slug: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        status: {
            title: "Status",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: {
                    active: "Activo",
                    inactive: "Inactivo",
                },
            },
        },
        search: {
            dataType: "array",
            disabled: {
                hidden: true
            }
        }
    },
});

export const specialtyCallbacks = buildEntityCallbacks({
    onPreSave: ({ values }) => {
        values.modified = new Date();
        values.nameStr = '';
        values.search = [];
        values.slug = '';
        return values;
    },
    onSaveSuccess: (props: EntityOnSaveProps<Specialty>) => {
        console.log("onSaveSuccess:specialty");
    }
});

export const buildSpecialty = buildCollection({
    path: "specialties",
    schema: specialtySchema,
    name: "Especialidades",
    initialSort: ["nameStr", "asc"],
    group: "Otros",
    permissions: ({ authController }) => ({
        edit: authController.extra.roles.includes("administrador"),
        create: authController.extra.roles.includes("administrador"),
        delete: authController.extra.roles.includes("administrador"),
    }),
    callbacks: specialtyCallbacks,
    defaultSize: "xs",
    inlineEditing: true,
    textSearchEnabled: false,
    properties: ["name", "col_description"],
    subcollections: [],
    exportable: false,
    selectionEnabled: true,
    pagination: true,
    filterCombinations: [{ nameStr: "desc" }]
})