import { buildCollection, buildEntityCallbacks, buildSchema, EntityOnDeleteProps, EntityOnSaveProps, PropertiesOrBuilder } from "@camberi/firecms";

export type Page = {
  title: string;
  content: string;
  status: string;
  slug: string;
  date: Date;
};

const PageProperties: PropertiesOrBuilder<Page> = {
  title: {
    title: "Título",
    validation: { required: true },
    dataType: "string",
  },
  slug: {
    title: 'Slug',
    dataType: "string",
    validation: {
      required: false
    },
    description:"No modificar este valor"
  },
  content: {
    title: "Contenido",
    validation: { required: false },
    dataType: "string",
    config: {
      markdown: true,
      multiline: true
    }
  },
  status: {
    title: "Status",
    validation: { required: true },
    dataType: "string",
    config: {
      enumValues: {
        active: "Activo",
        inactive: "Inactivo",
        pending: "Pendiente",
      },
    },
  },
  date: {
    dataType: "timestamp",
    disabled: {
      hidden: true
    },
    validation: {
      required: true
    }
  },
}

export const PagesSchema = buildSchema<Page>({
  name: "Entrada",
  description: "Entradas de la plataforma",
  defaultValues: {
    date: new Date(),
    status: "active"
  },
  properties: PageProperties
});

export const PagesCallbacks = buildEntityCallbacks({
  onPreSave: ({
    schema,
    path,
    entityId,
    values,
    status
  }) => {

    // values.slug = values.title.normalize('NFD')
    //   .replace(/[\u0300-\u036f]/g, '')
    //   .toLowerCase()
    //   .trim()
    //   .replace(/\s+/g, '-')
    //   .replace(/[^\w-]+/g, '')
    //   .replace(/--+/g, '-');
    // values.slug = values.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Page>) => {
    //console.log("onSaveSuccess", props);
  },
  onSaveFailure: (props: EntityOnSaveProps<Page>) => {
    // console.log("onSaveFailure", props);
  },
  onPreDelete: ({
    schema,
    path,
    entityId,
    entity,
    context
  }: EntityOnDeleteProps<Page>
  ) => {
    // if (context.authController.user)
    //   throw Error("Product deletion not allowed");
  },
  onDelete: (props: EntityOnDeleteProps<Page>) => {
    //console.log("onDelete", props);
  },
});

export const buildPage = buildCollection({
  path: "pages",
  defaultSize: "xs",
  schema: PagesSchema,
  name: "Páginas",
  initialSort: ["date", "desc"],
  inlineEditing: false,
  properties: ["title", "slug", "content", "status", "date"],
  group: "Contenido",
  exportable: false,
  pagination: true,
  description: "Crear páginas de contendio estatico",
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
    create: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
    delete: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor") || authController.extra.roles.includes("videos"),
  }),
  callbacks: PagesCallbacks
});