import { AdditionalColumnDelegate, ArrayProperty, buildCollection, buildEntityCallbacks, buildProperty, buildSchema, EntityOnSaveProps, MapProperty, Property } from "@camberi/firecms";

export type CustomFieldValidator = (props: {
  name: string,
  value: any,
  property: Property,
  parentProperty?: MapProperty | ArrayProperty
}) => Promise<boolean>;

export type Banner = {
  title: string;
  nameStr: string;
  image: string;
  status: string;
  date: Date;
  modified: Date;
  publication_date: Date;
  order: number;
  wp_id: string;
  typeS: string;
  valueS: string;
  analyticsLabel: string;
};

export const bannerSchema = buildSchema<Banner>({
  name: "Banner",
  description: "Banners rotatorios en incio de la plataforma",
  defaultValues: {
    date: new Date(),
    status: "active",
    typeS: "iab",
    publication_date: new Date(),
  },
  properties: {
    date: {
      dataType: "timestamp",
      disabled: {
        hidden: true
      },
      validation: {
        required: true
      }
    },
    modified: {
      dataType: "timestamp",
      disabled: {
        hidden: true
      }
    },
    nameStr: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    },
    title: {
      title: "Título",
      validation: { required: true },
      dataType: "string",
    },
    image: buildProperty({
      title: "Imágen",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images-banner",
          acceptedFiles: ["image/*"],
          storeUrl: true
        },
      },
    }),
    typeS: {
      dataType: "string",
      title: "Tipo de banner",
      validation: { required: true },
      config: {
        enumValues: {
          "iab": "URL",
          "modal": "Artículo",
          "search": "Búsqueda multimedia"
        }
      }
    },
    valueS: ({ values }) => ({
      title: values && values.typeS && values.typeS === 'search' ? "Búsqueda multimedia" : (values && values.typeS && values.typeS === 'modal' ? "Artículo" : 'URL'),
      dataType: "string",
      validation: { required: true, url: values && values.typeS && values.typeS === 'iab' ? true : false },
    }),
    order: {
      title: "Órden",
      validation: {
        required: true,
        unique: true
      },
      dataType: "number",
    },
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      description: "Visibilidad de Banner",
      config: {
        enumValues: {
          active: "Activo",
          inactive: "Inactivo",
        },
      },
    },
    publication_date: {
      title: "Fecha de publicación",
      dataType: "timestamp",
      validation: {
        required: true
      }
    },
    wp_id: {
      dataType: "string",
      disabled: {
        hidden: true
      }
    },
    analyticsLabel: {
      title: "Etiqueta de analytics",
      validation: { required: false },
      dataType: "string",
    },
  },
});

export const bannerAdditionalColumn: AdditionalColumnDelegate<Banner> = {
  id: "col_value",
  title: "Valor",
  builder: ({ entity, context }) => {
    return <div>{entity.values.valueS}</div>
  }
};

export const bannerCallbacks = buildEntityCallbacks({
  onPreSave: ({ values }) => {
    values.modified = new Date();
    values.nameStr = '';
    return values;
  },
  onSaveSuccess: (props: EntityOnSaveProps<Banner>) => {
    console.log("onSaveSuccess", props);
  }
});

export const buildBanner = buildCollection({
  path: "banners",
  schema: bannerSchema,
  inlineEditing: false,
  name: "Banners",
  group: "Contenido",
  initialSort: ["order", "asc"],
  properties: ["order", "title", "image", "status", "typeS", "col_value", "publication_date"],
  additionalColumns: [bannerAdditionalColumn],
  permissions: ({ authController }) => ({
    edit: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor"),
    create: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor"),
    delete: authController.extra.roles.includes("administrador") || authController.extra.roles.includes("editor"),
  }),
  callbacks: bannerCallbacks
})