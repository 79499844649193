import { AdditionalColumnDelegate, buildEntityCallbacks, buildSchema, EntityOnSaveProps, SelectionController, useSnackbarController, buildCollection } from "@camberi/firecms";
import { Button } from "@mui/material";

export type Category = {
    name: string;
    description: string;
    date: Date;
    modified: Date;
    nameStr: string;
    search: string[];
    wp_id: string;
    slug: string;
    status: string;
};

export const categorySchema = buildSchema<Category>({
    name: "Categoría",
    defaultValues: {
        date: new Date(),
        status: "active"
    },
    properties: {
        date: {
            dataType: "timestamp",
            disabled: {
                hidden: true
            },
            validation: {
                required: true
            }
        },
        modified: {
            dataType: "timestamp",
            disabled: {
                hidden: true
            }
        },
        nameStr: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        name: {
            title: "Nombre",
            validation: { required: true },
            dataType: "string",
            columnWidth: 250
        },
        description: {
            title: "Descripción",
            validation: { required: false },
            dataType: "string",
            config: {
                multiline: true,
                markdown: true
            }
        },
        wp_id: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        slug: {
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        status: {
            title: "Status",
            validation: { required: true },
            dataType: "string",
            config: {
                enumValues: {
                    active: "Activo",
                    inactive: "Inactivo",
                },
            },
        },
        search: {
            dataType: "array",
            disabled: {
                hidden: true
            }
        }
    },
});

export const categoryAdditionalColumn: AdditionalColumnDelegate<Category> = {
    id: "col_description",
    title: "Descripción",
    builder: ({ entity, context }) => {
        return <div>{entity.values.description}</div>
    }
};

export const categoryCallbacks = buildEntityCallbacks({
    onPreSave: ({ values }) => {
        values.modified = new Date();
        values.nameStr = '';
        values.search = [];
        values.slug = '';
        return values;
    },
    onSaveSuccess: (props: EntityOnSaveProps<Category>) => {
        console.log("onSaveSuccess:category");
    }
});

export const CategoryExtraActions = ({ selectionController }: { selectionController: SelectionController }) => {
    const snackbarContext = useSnackbarController();
    const { selectedEntities } = selectionController;
    const onClick = (event: React.MouseEvent) => {
        const count = selectedEntities ? selectedEntities.length : 0;
        snackbarContext.open({
            type: "success",
            message: `User defined code here! ${count} products selected`
        });
    };
    return (
        <Button onClick={onClick} color="primary">
            All
        </Button>
    );
}

export const buildCategory = buildCollection({
    path: "categories",
    schema: categorySchema,
    name: "Categorías",
    initialSort: ["nameStr", "asc"],
    group: "Otros",
    permissions: ({ authController }) => ({
        edit: authController.extra.roles.includes("administrador"),
        create: authController.extra.roles.includes("administrador"),
        delete: authController.extra.roles.includes("administrador"),
    }),
    callbacks: categoryCallbacks,
    defaultSize: "xs",
    inlineEditing: true,
    textSearchEnabled: false,
    properties: ["name", "col_description"],
    subcollections: [],
    additionalColumns: [categoryAdditionalColumn],
    exportable: false,
    selectionEnabled: true,
    pagination: true,
    extraActions: CategoryExtraActions
})